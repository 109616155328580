<template>
    <div class="p-info-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("basic information") }}</div>
            <button class="button danger-button" @click.prevent="backToProfile">Cancel</button>
        </div>
        <form v-if="!isContentLoading">
            <div class="form-box basic-info-box">
                <div class="basic-info row mr-3">
                    <div class="form-group col-md-6">
                        <label for="first_name">{{ $t("First name") }}*</label>
                        <input type="text" id="first_name" class="form-control" :placeholder="$t('enter your first name')" v-model="candidate.first_name" maxlength="50">
                        <error-message :message="errors.first_name"/>

                    </div>
                    <div class="form-group col-md-6">
                        <label for="last_name">{{ $t("Last name") }}*</label>
                        <input type="text" id="last_name" class="form-control" :placeholder="$t('enter your last name')" v-model="candidate.last_name" maxlength="50">
                        <error-message :message="errors.last_name"/>

                    </div>
                    <div class="form-group col-md-6">
                        <label for="email">{{ $t("email address") }}*</label>
                        <input type="email" id="email" class="form-control" readonly :placeholder="$t('enter your email address')" v-model="candidate.email" maxlength="100">
                        <error-message :message="errors.email"/>

                    </div>
                    <div class="form-group col-md-6">
                        <label for="phone_number">{{ $t("Phone number") }}*</label>
                        <input id="phone_number" type="text" class="form-control" :placeholder="$t('enter your phone number')" v-model="candidate.mobile_number" maxlength="20">
                        <error-message :message="errors.mobile_number"/>
                    </div>
                    <div class="form-group col-md-6">
                        <label>{{ $t("Nationality") }}</label>
                        <multiselect
                                v-model="selectedNationality"
                                :options="nationalities"
                                :placeholder="$t('enter your nationality')"
                                track-by="value"
                                label="text"
                                id="nationality"
                        ></multiselect>
                        <error-message :message="errors.nationality"/>

                    </div>
                    <div class="form-group col-md-6">
                        <label for="national_id">{{ $t("National ID") }}</label>
                        <input type="text" id="national_id" class="form-control" :placeholder="$t('National ID')" v-model="candidate.identity_number" maxlength="100">
                        <error-message :message="errors.identity_number"/>

                    </div>
                    <div class="form-group col-md-6">
                        <label>{{$t(`Time zone`)}}</label>
                        <multiselect
                                id="salary_type"
                                v-model="timezone"
                                :options="timeZones"
                                :placeholder="$t('Select timezone')"
                                track-by="time_zone"
                                label="time_zone"
                                name = "time-zone"
                                :allow-empty="false"
                                deselect-label=""
                        ></multiselect>
                        <error-message :message="errors.time_zone"/>
                    </div>


                    <div class="form-group col-md-3">
                        <label>{{ $t("Gender") }}*</label>
                        <multiselect
                                v-model="gender"
                                :options="genders"
                                :placeholder="$t('select gender')"
                                track-by="id"
                                label="name"
                                id="gender"
                                selectLabel="Select"
                        ></multiselect>
                        <error-message :message="errors.gender"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label>{{ $t("Date of Birth") }}*</label>
                        <div class="date-picker">
                            <date-picker
                                    v-model="candidate.dob"
                                    :placeholder="$t('mm/dd/yyyy')"
                                    autocomplete="off"
                                    :config="datePickerOptions"
                            ></date-picker>
                            <error-message :message="errors.dob"/>
                        </div>
                    </div>


                    <div class="form-group col-md-6">
                        <label for="current_salary">{{ $t("Current salary") }}</label>
                        <input type="text" id="current_salary" class="form-control" :placeholder="$t('type amount with currency')" v-model="candidate.current_salary" maxlength="20"/>
                        <error-message :message="errors.current_salary"/>

                    </div>
                    <div class="form-group col-md-6">
                        <label for="expected_salary">{{ $t("Expected salary") }}</label>
                        <input type="text" id="expected_salary" class="form-control" :placeholder="$t('type amount with currency')" v-model="candidate.expected_salary" maxlength="20">
                        <error-message :message="errors.expected_salary"/>

                    </div>

                    <div class="form-group col-md-6">
                        <label for="social_link_1">{{ $t("LinkedIn profile link") }}</label>
                        <input type="text" id="social_link_1" class="form-control" placeholder="http://www.linkedin.com/yourProfile" v-model="candidate.social_link[1]" maxlength="250">
                        <error-message :message="errors['social_link.1']"/>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="social_link_3">{{ $t("Twitter profile link") }}</label>
                        <input type="text" id="social_link_3" class="form-control" placeholder="http://www.twitter.com/yourProfile" v-model="candidate.social_link[3]" maxlength="250">
                        <error-message :message="errors['social_link.3']"/>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="social_link_2">{{ $t("Facebook profile link") }}</label>
                        <input type="text" class="form-control" id="social_link_2" placeholder="http://www.facebook.com/yourProfile" v-model="candidate.social_link[2]" maxlength="250">
                        <error-message :message="errors['social_link.2']"/>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="intro_video_link">{{ $t("Intro Video Link") }}</label>
                        <input id="intro_video_link" type="text" class="form-control" :placeholder="$t('enter your intro video link')" v-model="candidate.intro_video_link" maxlength="250">
                        <error-message :message="errors.intro_video_link"/>
                    </div>
                    <div class="form-group col-md-12">
                        <div class="section-gap d-flex justify-content-end">
                            <!--<button class="button danger-button" @click.prevent="cancelEditMode">{{ $t("Cancel")}}</button>-->
                            <submit-button :click="submitCandidateInfo" :loading="isLoading" class="info-button ml-4 text-capitalize">{{ $t("update information") }}</submit-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
import {getCandidateInfo as loadCandidateInfo, getNationalities} from "../../../../app/api/CommonRequest";
import {GENDER} from "../../../../constants/enums";
import Multiselect from "vue-multiselect";
import {mapActions} from "vuex";
import {UPDATE_USER_DATA} from '../../../../constants/action-type';
import client from "../../../../app/api/Client";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import {datePickerIcons} from "../../../../config/options";
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from "moment";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
    name: "Form",
    components: {
        ErrorMessage,
        Multiselect,
        datePicker
    },
    data() {
        return {
            isLoading: false,
            gender: {
                id: null,
                name: '---'
            },
            nationalities: [],
            selectedNationality: null,
            candidate: {
                first_name: null,
                email: null,
                nationality: null,
                dob: null,
                last_name: null,
                country_id: null,
                mobile_number: null,
                identity_number: null,
                gender: null,
                social_link: [],
                intro_video_link: null,
                current_salary: null,
                expected_salary: null,
                time_zone: null,
            },
            additionalInfo: {
                photo: "/assets/images/placeholder/profile_150X150.jpeg",
            },
            errors: {},
            isContentLoading: true,
            timeZones: [],
            timezone: {
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        }
    },
    computed: {
        genders() {
            return [
                {
                    id: '',
                    name: '---'
                },
                {
                    id: GENDER.MALE,
                    name: this.$t('male')
                },
                {
                    id: GENDER.FEMALE,
                    name: this.$t('female')
                },
                {
                    id: GENDER.OTHER,
                    name: this.$t('other')
                },
            ];
        },
        datePickerOptions() {
            return {
                format: 'MM/DD/YYYY',
                maxDate: moment().endOf('day'),
                icons: datePickerIcons,
                useCurrent: false
            }
        }
    },
    methods: {
        ...mapActions([UPDATE_USER_DATA]),
        async getTimeZones() {
            let {data: {data}} = await client().get('/timezones');
            this.timeZones = data;
        },
        async getCandidateInfo() {
            this.isContentLoading = true;
            try {
                let {data} = await loadCandidateInfo();
                this.candidate = data.candidate;
                this.candidate.dob = data.candidate.dob ? moment(data.candidate.dob) : null;
                this.additionalInfo = data.additional;
                this.hasPhoto = data.additional.hasPhoto;
                if(this.candidate.nationality) {
                    this.selectedNationality.text = this.candidate.nationality;
                    this.selectedNationality.value = this.candidate.nationality;
                }
                if (this.candidate.gender) {
                    this.gender = _.find(this.genders, {id: this.candidate.gender});
                    this.gender.name = this.$t(this.gender.name);
                }

                if (this.candidate.time_zone) {
                    this.timezone.time_zone = this.candidate.time_zone;
                }
            } catch (e) {
            }
            this.isContentLoading = false;

        },
        backToProfile() {
            this.$router.push({name: 'candidate.basic-info'})
        },
        submitCandidateInfo() {
            this.isLoading = true;
            this.candidate.gender = this.gender ? this.gender.id : '';
            this.candidate.nationality = this.selectedNationality ? this.selectedNationality.value : '';
            this.candidate.time_zone = this.timezone.time_zone;

            client()
                .post('/candidate', this.candidate)
                .then(({data}) => {
                    this.$toast.success(this.$t(data.message));
                    if (data.data.hasOwnProperty('email') && data.data.hasOwnProperty('name')) {
                        this[UPDATE_USER_DATA](data.data);
                    }
                    this.backToProfile();
                })
                .catch(error => {
                    return this.showErrorText(error)
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        showErrorText(error) {
            if (typeof error == 'string') {
                return this.$toast.error(error);
            } else if (error.response !== undefined && error.response.status === 422) {/* validation error */

                if (typeof error.response.data.message == 'string') {
                    return this.$toast.error(error.response.data.message);
                }
                this.errors = _helper.serializeValidationMessage(error.response.data.message);
                this.$toast.error(this.$t('Oops! Please check your input'));
            } else {
                // this.$toast.error(this.$t('Oops! Somethings was wrong'));
            }
        },


    },
    async mounted() {
        await this.getTimeZones();
        await this.getCandidateInfo();
        let {data} = await getNationalities();
        this.nationalities = data;
    }

}
</script>

<style scoped>

</style>
